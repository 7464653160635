
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "about-wealth-pro",
  components: {},
  setup() {
    onMounted(() => {
      setCurrentPageTitle("About IBN WealthPro");
    });
  },
  created() {
    //Set page title
    document.title = "About | " + process.env.VUE_APP_TITLE;
  }
});
